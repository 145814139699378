<template>
  <div class="container mx-auto">
    <form class="w-100" @submit.prevent="searchSentenza('ricerca in corso...', false)" @keypress="clearMsg()">
      <h1 class="text-2xl font-bold text-center mb-2 text-primary-color">PREGIUDIZIALI E FOLLOW-UP</h1>

      <div class="text-center text-sm mb-8">La banca dati</div>

      <hr class="h-1 mb-12 bg-grigio border-0" />

      <div class="refine-filter-container w-100 mb-12">
        <div class="text-base uppercase font-bold mb-6">Cerca</div>
        <div class="mb-12">
          <input type="text" name="search" id="search" v-model="searchStore.query" placeholder="Inserisci qui il testo da ricercare" />
        </div>

        <div class="text-base uppercase font-bold mb-6">Opzioni di ricerca</div>
        <div class="opzioni-ricerca mb-12 grid gap-8 md:grid-cols-2 bg-grigio p-5 rounded-3xl">
          <div>
            <fieldset>
              <input class="mb-4" placeholder="Numero causa / R.G." type="text" name="causa" id="causa" v-model="searchStore.numero_causa" />
              <input class="mb-4" placeholder="Parti principali" type="text" name="parti_principali" id="parti_principali" v-model="searchStore.parti_principali" />
              <input class="mb-4" placeholder="Parti intervenienti" type="text" name="parti_intervenienti" id="parti_intervenienti" v-model="searchStore.parti_intervenienti" />
              <input class="mb-4" placeholder="Giudice a quo" type="text" name="giudice" id="giudice" v-model="searchStore.giudice_a_quo" />
              <input class="mb-4" placeholder="Argomenti" type="text" name="argomenti" id="argomenti" v-model="searchStore.argomenti" />
            </fieldset>
          </div>
          <div>
            <fieldset>
              <input class="mb-4" placeholder="Diritto primario" type="text" name="diritto_primario" id="diritto_primario" v-model="searchStore.ogg_interpretazione_diritto_primario" />
              <input class="mb-4" placeholder="Diritto subprimario" type="text" name="diritto_subprimario" id="diritto_subprimario" v-model="searchStore.ogg_interpretazione_diritto_subprimario" />
              <input class="mb-4" placeholder="Diritto derivato" type="text" name="diritto_derivato" id="diritto_derivato" v-model="searchStore.ogg_interpretazione_diritto_derivato" />
              <div>
                Tipo rinvio pregiudiziale:
                <br />
                <div class="flex items-center hover:cursor-pointer" v-for="rp in rinvioPregiudiziale">
                  <input type="radio" name="rinvioPregiudiziale" :id="`rinvioPregiudiziale_${rp}`" class="opacity-0 absolute h-5 w-5 hover:cursor-pointer" :value="rp" v-model="searchStore.rinvio_pregiudiziale" />
                  <RoundedCheckbox />
                  <label class="inline-block" :for="`rinvioPregiudiziale_${rp}`">
                    <span class="text-sm">{{ rp }}</span>
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="text-base uppercase font-bold mb-6">Data ricerca</div>
        <div class="opzioni-ricerca-data grid gap-8 md:grid-cols-2 bg-grigio p-5 rounded-3xl">
          <div>
            <div class="flex items-center mr-4 mb-2">
              <input type="checkbox" name="search-date-type" @change="changeDataTipo($event)" value="single" :checked="searchStore.tipo_data_ricerca == 'single'" class="opacity-0 absolute h-6 w-6" id="search-date-type-single" />
              <div class="bg-white border-2 rounded-md border--primary-color-400 h-6 w-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-primary-color">
                <svg class="fill-current hidden w-3 h-3 text--primary-color-600 pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(-9 -11)" fill="#366b8d" fill-rule="nonzero">
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label for="search-date-type-single"><span class="text-grigio-scuro">Ricerca per data</span></label>
            </div>

            <!-- <input type="date" name="single-date" :disabled="searchStore.tipo_data_ricerca != 'single'" v-model="searchStore.data_pronuncia" /> -->
            <Datepicker name="single-date" :enable-time-picker="false" :disabled="searchStore.tipo_data_ricerca != 'single'" hide-offset-dates locale="it" format="dd/MM/yyyy" placeholder="gg/mm/aaaa" auto-apply v-model="searchStore.data_pronuncia"></Datepicker>
          </div>
          <div>
            <div class="flex items-center mr-4 mb-2">
              <input type="checkbox" name="search-date-type" @change="changeDataTipo($event)" value="multiple" :checked="searchStore.tipo_data_ricerca == 'multiple'" class="opacity-0 absolute h-6 w-6" id="search-date-type-multiple" />
              <div class="bg-white border-2 rounded-md border--primary-color-400 h-6 w-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-primary-color">
                <svg class="fill-current hidden w-3 h-3 text--primary-color-600 pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(-9 -11)" fill="#366b8d" fill-rule="nonzero">
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label for="search-date-type-multiple"><span class="text-grigio-scuro">Ricerca per intervallo di date</span></label>
            </div>

            <div class="opzioni-ricerca-data">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Datepicker name="multiple-date-from" :enable-time-picker="false" :disabled="searchStore.tipo_data_ricerca != 'multiple'" v-model="searchStore.start_data_pronuncia" hide-offset-dates locale="it" format="dd/MM/yyyy" placeholder="gg/mm/aaaa" auto-apply></Datepicker>
                <Datepicker name="multiple-date-from" :enable-time-picker="false" :disabled="searchStore.tipo_data_ricerca != 'multiple'" v-model="searchStore.end_data_pronuncia" hide-offset-dates locale="it" format="dd/MM/yyyy" placeholder="gg/mm/aaaa" auto-apply></Datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <div class="min-[489px]:inline-block mb-5 min-[489px]:mr-5 md:mr-10">
          <button type="reset" class="inline-block" @click="searchStore.clear()"><span>Resetta</span></button>
        </div>
        <div class="min-[489px]:inline-block mb-5">
          <button type="submit" class="inline-block btn-three"><span>Ricerca</span></button>
        </div>
      </div>
    </form>

    <div class="search-results">
      <div :class="`mt-24 py-10 bg-grigio ${searchStore.results.length > 0 ? 'slide-in-fwd-center' : 'hidden'}`">
        <div class="uppercase text-primary-color text-2xl text-center font-bold poppins mb-6" v-if="searchStore.total > 0">Risultati trovati: {{ searchStore.total }}</div>

        <div class="uppercase text-primary-color text-2xl text-center font-bold poppins mb-6" v-if="msg">{{ msg }}</div>

        <ul>
          <li v-for="(result, idx) in searchStore.results" class="bouble rounded-3xl p-5 mb-8 bg-white poppins">
            <div class="hover:cursor-pointer" @click="router.push(`/sentenza/${result.id}`)">
              <div class="mb-6">
                <strong class="sentenza_title text-lg font-bold">
                  {{ result.attributes.tipologia }}
                  <i>{{ result.attributes.numero_causa }}</i>
                  del {{ formatDate(result.attributes.data_pronuncia) }}
                </strong>
              </div>

              <div class="text-sm mb-4">
                Tipo rinvio pregiudiziale:
                <strong>{{ result.attributes.rinvio_pregiudiziale }}</strong> - Causa dinanzi alla Corte: <strong>{{ result.attributes.identificativo }}</strong>
              </div>

              <div class="leading-7">
                <span class="text-md" v-html="result.attributes.parti_principali.replace('contro', '<strong>contro</strong>').replace(/(,$)/g, '')"></span>
              </div>

              <hr class="my-4 border-t border-grigio" />

              <i class="text-[10px]">{{ result.attributes.argomenti }}</i>

              <hr class="my-4 border-t border-grigio" />
            </div>

            <div class="text-xs">
              <span class="inline-block mr-1">Collegamenti:</span>
              <div v-for="(ref, idx) in sortBy(result.attributes.correlati.data, 'attributes.data_pronuncia')" class="inline-block">
                <router-link class="links" :title="ref.tipologia" :to="`/sentenza/${ref.id}`" :data-tooltip="`${ref.attributes.tipologia} del ${ref.attributes.data_pronuncia}`">
                  {{ ref.attributes.tipologia.substring(0, 1) }}
                </router-link>
              </div>
            </div>
          </li>
        </ul>

        <div class="pages text-right" v-if="searchStore.pageCount > 1">
          <div class="inline-block" v-if="searchStore.currentPage > 1">
            <button type="button" class="small monospace" @click="dec">
              <span><font-awesome-icon :icon="['fas', 'angle-left']" /></span>
            </button>
          </div>
          <div class="ml-2 inline-block">Pagina {{ searchStore.currentPage }} di {{ searchStore.pageCount }}</div>
          <div class="ml-2 inline-block" v-if="searchStore.currentPage < searchStore.pageCount">
            <button type="button" class="small monospace" @click="inc">
              <span><font-awesome-icon :icon="['fas', 'angle-right']" /></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { sortBy } from 'underscore';

  import { ref, onMounted } from 'vue';
  import { useSearchStore } from './stores/search';

  import qs from 'qs';
  import axios from 'axios';
  import dayjs from 'dayjs';
  import 'dayjs/locale/it';
  import { useRouter } from 'vue-router';

  import RoundedCheckbox from './components/RoundedCheckbox';
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';

  const router = useRouter();
  const rinvioPregiudiziale = ref([]);
  const pageSize = 10;

  const correlatiFields = ['numero_causa', 'tipologia', 'data_pronuncia'];
  const populateParams = {
    correlati: {
      fields: correlatiFields,
    },
    documento: '*',
  };

  const searchStore = useSearchStore();

  const msg = ref('');
  const searchMode = ref('anyWords');

  const changeDataTipo = (e) => {
    // searchStore.tipo_data_ricerca = searchType;

    if (e.srcElement.checked) {
      searchStore.tipo_data_ricerca = e.srcElement.value;
    } else {
      if (searchStore.tipo_data_ricerca == 'single') {
        searchStore.data_pronuncia = '';
      } else {
        searchStore.start_data_pronuncia = '';
        searchStore.end_data_pronuncia = '';
      }
      searchStore.tipo_data_ricerca = '';
    }
  };

  const clearMsg = () => {
    msg.value = '';
  };

  onMounted(async () => {
    const requests = ['rinvio_pregiudiziale'].map((r) => axios.get(`${process.env.API_URL}/enum/${r}`));
    const responses = await Promise.all(requests);

    rinvioPregiudiziale.value = responses[0].data;
  });

  const searchSentenza = async (txt, isPageLoading) => {
    if (!isPageLoading) {
      searchStore.currentPage = 1;
    }

    const searchWords = searchStore.tokenizeSearchTxt.map((i) => {
      return {
        contenuto: {
          $containsi: i,
        },
      };
    });

    const values = [];
    if (searchWords.length > 0) {
      values.push({ $or: searchWords });
    }

    if (searchStore.numero_causa.length > 0) {
      values.push({
        numero_causa: { $containsi: searchStore.numero_causa },
      });
    }
    if (searchStore.giudice_a_quo.length > 0) {
      values.push({
        giudice_a_quo: { $containsi: searchStore.giudice_a_quo },
      });
    }
    if (searchStore.parti_principali.length > 0) {
      values.push({
        parti_principali: { $containsi: searchStore.parti_principali },
      });
    }
    if (searchStore.parti_intervenienti.length > 0) {
      values.push({
        parti_intervenienti: {
          $containsi: searchStore.parti_intervenienti,
        },
      });
    }
    if (searchStore.rinvio_pregiudiziale.length > 0) {
      values.push({
        rinvio_pregiudiziale: {
          $eq: searchStore.rinvio_pregiudiziale,
        },
      });
    }
    if (searchStore.data_pronuncia.length > 0) {
      values.push({
        data_pronuncia: {
          $eq: searchStore.data_pronuncia,
        },
      });
    }
    if (searchStore.start_data_pronuncia.length > 0) {
      values.push({
        data_pronuncia: {
          $gte: searchStore.start_data_pronuncia,
        },
      });
    }
    if (searchStore.end_data_pronuncia.length > 0) {
      values.push({
        data_pronuncia: {
          $lte: searchStore.end_data_pronuncia,
        },
      });
    }
    if (searchStore.argomenti.length > 0) {
      values.push({ argomenti: { $containsi: searchStore.argomenti } });
    }
    if (searchStore.ogg_interpretazione_diritto_primario.length > 0) {
      values.push({
        ogg_interpretazione_diritto_primario: {
          $containsi: searchStore.ogg_interpretazione_diritto_primario,
        },
      });
    }
    if (searchStore.ogg_interpretazione_diritto_subprimario.length > 0) {
      values.push({
        ogg_interpretazione_diritto_subprimario: {
          $containsi: searchStore.ogg_interpretazione_diritto_subprimario,
        },
      });
    }
    if (searchStore.ogg_interpretazione_diritto_derivato.length > 0) {
      values.push({
        ogg_interpretazione_diritto_derivato: {
          $containsi: searchStore.ogg_interpretazione_diritto_derivato,
        },
      });
    }

    const hasFilters = values.length > 0;
    if (hasFilters) {
      const query = qs.stringify(
        {
          filters: {
            $and: values,
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );

      const params = qs.stringify(
        {
          sort: 'data_pronuncia:desc',
          fields: ['numero_causa', 'data_pronuncia', 'parti_principali', 'tipologia', 'argomenti', 'rinvio_pregiudiziale', 'identificativo'],
          pagination: { page: searchStore.currentPage, pageSize: pageSize },
          populate: populateParams,
        },
        {
          encodeValuesOnly: true,
        }
      );

      const endpoint = `${process.env.API_URL}/sentenze?${params}&${query}`;
      const res = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${process.env.TOKEN}`,
        },
      });

      searchStore.results = res.data.data;
      searchStore.pageCount = res.data.meta.pagination.pageCount;
      searchStore.total = res.data.meta.pagination.total;
      msg.value = '';

      searchStore.setPhraseSearch(searchMode.value);

      let sr = document.querySelector('.search-results');
      setTimeout(() => {
        sr.scrollIntoView(true);
      }, 200);
    } else {
      msg.value = 'Inserire almeno un criterio di ricerca';
    }
  };

  const inc = async () => {
    if (searchStore.currentPage < searchStore.pageCount) {
      searchStore.currentPage++;
      searchSentenza('caricamento pagina in corso ...', true);
    }
  };

  const dec = async () => {
    if (searchStore.currentPage > 1) {
      searchStore.currentPage--;
      searchSentenza('caricamento pagina in corso ...', true);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).locale('it').format('DD MMMM YYYY');
  };
</script>

<style>
  .dp__theme_light {
    --primary-color: #366b8d;
    --grigio: #efefef;
    --grigio-scuro: #6f6f6f;
    --grigio-medio: #c9c9c9;
    --text-main: #000;
    --dp-text-color: var(--text-main);
    --dp-hover-color: var(--grigio-medio);
    --dp-hover-text-color: var(--text-main);
    --dp-primary-color: var(--primary-color);
    --dp-border-color: var(--grigio-medio);
    --dp-menu-border-color: var(--grigio-medio);
    --dp-border-color-hover: var(--grigio-medio);
    --dp-disabled-color-text: var(--grigio-medio);
  }
  .dp__menu {
    border-radius: 20px;
  }
  .dp__input {
    /* border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--grigio-medio);
    height: 36px;
    color: var(--primary-color);
    width: 100%;
    background-color: #0000;
    margin-top: 5px;
    font-size: 16px; */
    height: 36px;
    font-size: 16px;
    margin-top: 5px;
    color: var(--primary-color);
    width: 100%;

    border: 0;
    background-color: var(--grigio);
    /* padding: 0px 15px; */
    border-radius: 50px;
  }
  .bg-grigio .dp__input {
    background-color: #ffffff;
  }

  .search-results {
    box-shadow: 0 0 0 100vmax var(--grigio);
    clip-path: inset(0 -100vmax);
  }
  .search-results ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .search-results ul li:hover {
    animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .links {
    background: var(--primary-color);
    height: 30px;
    width: 30px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    display: inline-block;
  }
  .links:hover {
    text-decoration: none;
    color: var(--primary-color);
    background: var(--grigio);
  }

  .slide-in-fwd-center {
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @keyframes slide-in-fwd-center {
    0% {
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes shadow-drop-center {
    0% {
      box-shadow: 0 0 0 0 transparent;
    }
    100% {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    }
  }
</style>
