<template>
  <footer class="mt-32 py-5">
    <div class="bg-grigio py-4">
      <div class="container mx-auto text-center">
        <!-- <div class="grid grid-cols-2 gap-4 md:gap-8 items-center"> -->
        <!-- <div class="grid items-center"> -->
        <!-- <div class="text-right"> -->
        <!-- <div class="inline-block border border-grigio-medio bg-white p-1"> -->
        <span class="inline-block border border-grigio-medio bg-white p-1"><img src="../../public/logo-unimi.png" width="200" alt="Università degli studi di Milano" /></span>
        <!-- </div> -->
        <!-- </div> -->
        <!-- <div>
            <img src="../../public/prin-2017.png" width="200" alt="PRIN 2017" />
          </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="bg-primary-color py-4">
      <div class="container mx-auto">
        <div class="text-center text-white">
          <h1 class="text-white mb-4">PREGIUDIZIALI E FOLLOW-UP</h1>
          <!-- <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center max-w-[500px] mx-auto"> -->
          <div class="grid grid-cols-1 items-center max-w-[500px] mx-auto">
            <!-- <div class="text-center">
              <p class="text-xs">
                Via Lombardia 1 - 20122 Milano
                <br />
                Tel.
                <strong><a href="tel:+390239394545">+39 02 3939 4545</a></strong>
                <br />
                <strong><a href="https://www.acme.com/posta-elettronica-certificata-pec">Posta Elettronica Certificata</a></strong>
                <br />
                C.F. 987654321 - P.I. 123456789
                <br />
                ©Copyright 2022
              </p>
            </div> -->
            <div class="text-center">
              <!-- <img src="../../public/Pastel-Aesthetic.png" class="h-28 mx-auto" /> -->
              <img src="../../public/prin-2017.png" class="h-28 mx-auto" alt="PRIN 2017" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-xs text-center">&copy; {{ new Date().getFullYear() }} All rights reserved.</p>
  </footer>
</template>
<script>
  export default {
    name: 'Footer',
  };
</script>
<script setup>
  const appName = process.env.APP_NAME;
</script>
