<template>
  <Header />

  <div class="wrapper">
    <!-- <div class="container mx-auto relative"><div class="mb-10 text-center"><img src="../public/Prova.png" class="mx-auto max-w-full" /></div></div> -->

    <router-view v-slot="{ Component, route }">
      <transition name="scale" mode="out-in">
        <div :key="route.name">
          <component :is="Component" :key="route.path" />
        </div>
      </transition>
    </router-view>
  </div>

  <Footer />

  <PoweredBy />
</template>
<script>
  import Header from './components/Header';
  import Footer from './components/Footer';
  import PoweredBy from './components/PoweredBy';

  export default {
    components: { Header, Footer, PoweredBy },
  };
</script>

<style>
  @font-face {
    font-family: 'SF-UI-Thin';
    src: url('../public/fonts/SF-UI-Display-Thin.woff');
  }

  @font-face {
    font-family: 'SF-UI';
    src: url('../public/fonts/SF-UI-Display-Medium.woff');
  }

  @font-face {
    font-family: 'SF-UI-Bold';
    src: url('../public/fonts/SF-UI-Display-Bold.woff');
  }

  @font-face {
    font-family: 'NewYork';
    src: url('../public/fonts/NewYorkMedium-Regular.woff');
  }
  :root {
    /* --primary-color: #002841; */
    --primary-color: #366b8d;
    --grigio: #efefef;
    --grigio-scuro: #6f6f6f;
    --grigio-medio: #c9c9c9;

    --links: var(--primary-color);
    --text-main: #000;
    --label: #6f6f6f;

    --background: transparent;
    --focus: var(--primary-color);

    --border: #c9c9c9;

    --scrollbar-thumb: var(--grigio) !important;
    --scrollbar-thumb-hover: rgb(217 217 217) !important;

    --max-width: 1020px;
  }
  @media (prefers-color-scheme: dark) {
    :root {
      --scrollbar-thumb: var(--grigio) !important;
      --scrollbar-thumb-hover: rgb(217 217 217) !important;
    }
  }

  html:not(.is-touch) *::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  html:not(.is-touch) *::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background-color: transparent !important;
  }
  html:not(.is-touch) *::-webkit-scrollbar-thumb {
    background-color: var(--grigio-scuro) !important;
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    html {
      scroll-behavior: smooth;
    }
  }

  *,
  *:focus,
  *:active,
  *:hover {
    outline: none !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:focus + label::before {
    outline: none !important;
  }
  ::placeholder {
    font-size: 12px;
  }

  #app {
    width: 100%;
  }
  body {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'SF-UI-Thin';
  }
  .font-bold,
  strong,
  b {
    font-weight: normal !important;
    font-family: 'SF-UI-Bold';
    /* font-family: 'SF-UI'; */
  }
  .sf-ui-bold {
    font-family: 'SF-UI-Bold';
  }

  .poppins {
    font-family: 'Poppins', sans-serif;
  }
  .monospace {
    font-family: monospace;
  }

  a.text-grigio:hover {
    text-shadow: 0 0 1px var(--grigio);
  }

  input {
    margin: 0;
  }
  input::placeholder,
  textarea::placeholder,
  input::-webkit-datetime-edit-fields-wrapper {
    color: var(--grigio-scuro) !important;
    font-style: italic;
    font-size: 14px;
    font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
    opacity: 0.7;
  }
  input[type='text'],
  input[type='date'] {
    /* border-bottom: 1px solid var(--grigio-medio); */
    /* background-color: transparent; */
    height: 36px;
    font-size: 16px;
    margin-top: 5px;
    color: #000;
    width: 100%;
    font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
    border: 0;
    background-color: var(--grigio);
    padding: 0px 15px;
    border-radius: 50px;
  }
  .bg-grigio input[type='text'],
  input[type='date'] {
    background-color: #ffffff;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  input:-internal-autofill-selected {
    background-color: var(--grigio) !important;
    box-shadow: 0 0 0 30px var(--grigio) inset !important;
    color: #000 !important;
    font-size: 16px !important;
    font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  }
  .bg-grigio input:-webkit-autofill,
  .bg-grigio input:-webkit-autofill:hover,
  .bg-grigio input:-webkit-autofill:focus,
  .bg-grigio textarea:-webkit-autofill,
  .bg-grigio textarea:-webkit-autofill:hover,
  .bg-grigio textarea:-webkit-autofill:focus,
  .bg-grigio select:-webkit-autofill,
  .bg-grigio select:-webkit-autofill:hover,
  .bg-grigio select:-webkit-autofill:focus,
  .bg-grigio input:-internal-autofill-selected {
    background-color: #ffffff !important;
    box-shadow: 0 0 0 30px white inset !important;
    color: #000 !important;
    font-size: 16px !important;
    font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  }
  input::-internal-input-suggested {
    font-size: 16px !important;
  }
  input[type='date' i]::-webkit-calendar-picker-indicator {
    width: 2em;
    height: 2em;
  }

  input:checked + div {
    @apply border-primary-color;
  }
  input:checked + div svg {
    @apply block;
  }

  input + div + label {
    cursor: pointer;
  }

  input[type='submit'],
  input[type='button'],
  button[type='submit'],
  button[type='button'],
  .btn {
    padding: 16px 72px;
    color: #ffffff;
    text-transform: uppercase;
    box-shadow: -4px 4px 3px #00000015;
    border-radius: 50px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.5s;
    position: relative;
  }
  input[type='submit']:is(:hover, :active),
  input[type='button']:is(:hover, :active),
  button[type='submit']:is(:hover, :active),
  button[type='button']:is(:hover, :active),
  .btn:is(:hover, :active) {
    color: var(--primary-color);
  }
  input[type='submit']::before,
  input[type='button']::before,
  button[type='submit']::before,
  button[type='button']::before,
  .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--primary-color);
    border-radius: 50px;
    transition: all 0.3s;
  }
  input[type='submit']:is(:hover, :active)::before,
  input[type='button']:is(:hover, :active)::before,
  button[type='submit']:is(:hover, :active)::before,
  button[type='button']:is(:hover, :active)::before,
  .btn:is(:hover, :active)::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  input[type='submit'] span,
  input[type='button'] span,
  button[type='submit'] span,
  button[type='button'] span,
  .btn span {
    z-index: 2;
    position: relative;
  }
  input[type='submit'].small,
  input[type='button'].small,
  button.small,
  .btn.small,
  .inverse.small {
    padding: 5px 11px;
  }

  input[type='reset'],
  button[type='reset'],
  .inverse {
    padding: 16px 72px;
    color: var(--primary-color);
    background-color: #ffffff;
    text-transform: uppercase;
    box-shadow: -4px 4px 3px #00000015;
    border-radius: 50px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.5s;
    position: relative;
  }
  input[type='reset']:is(:hover, :active),
  button[type='reset']:is(:hover, :active),
  .inverse:is(:hover, :active) {
    color: var(--primary-color);
  }
  input[type='reset']::before,
  button[type='reset']::before,
  .inverse::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--grigio);
    border-radius: 50px;
    transition: all 0.3s;
  }
  input[type='reset']:is(:hover, :active)::before,
  button[type='reset']:is(:hover, :active)::before,
  .inverse:is(:hover, :active)::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  input[type='reset'] span,
  button[type='reset'] span,
  .inverse span {
    z-index: 2;
    position: relative;
  }

  [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    border-radius: 2px;
    background-color: var(--links);
    color: #ffffff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.2;
  }
  [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid var(--links);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
    font-size: 0;
    line-height: 0;
  }
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.5s ease;
  }

  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
</style>
