import { defineStore } from 'pinia';

const defaultState = {
  numero_causa: '',
  query: '',
  giudice_a_quo: '',
  parti_principali: '',
  parti_intervenienti: '',
  argomenti: '',
  ogg_interpretazione_diritto_primario: '',
  ogg_interpretazione_diritto_subprimario: '',
  ogg_interpretazione_diritto_derivato: '',
  rinvio_pregiudiziale: '',
  tipo_data_ricerca: '',
  data_pronuncia: '',
  start_data_pronuncia: '',
  end_data_pronuncia: '',
  phraseSearch: true,
  results: [],
  currentPage: 1,
  pageCount: 0,
  total: 0,
};

export const useSearchStore = defineStore({
  id: 'search',
  state: () => ({
    ...defaultState,
  }),
  getters: {
    tokenizeSearchTxt() {
      return this.query.trim().length > 0 ? this.query.replace(/\s+/g, ' ').split(' ') : [];
    },
  },
  actions: {
    setPhraseSearch(searchMode) {
      this.phraseSearch = searchMode === 'phrase' ? true : false;
    },
    clear() {
      Object.assign(this, defaultState);
    },
  },
});
