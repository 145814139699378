import * as Sentry from "@sentry/vue";

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import Home from './Home.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faDownload,
  faArrowLeft,
  faLeftLong,
  faRightLong,
  faMinus,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faAngleRight,
  faAngleLeft,
  faGavel,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(faDownload, faArrowLeft, faLeftLong, faRightLong, faMinus, faPlus, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faAngleRight, faAngleLeft, faGavel, faBars);

globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = true;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/chi-siamo',
    name: 'About',
    component: () => import('./info/About.vue'),
  },
  {
    path: '/ricerca',
    name: 'Ricerca',
    component: () => import('./info/Search.vue'),
  },
  {
    path: '/progetto',
    name: 'Progetto',
    component: () => import('./info/Project.vue'),
  },
  {
    path: '/ocr',
    name: 'Ocr',
    component: () => import('./Ocr.vue'),
  },
  {
    path: '/sentenza/:id',
    name: 'Sentenza',
    component: () => import('./Sentenza.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.APP_NAME} - ${to.name}`;
  next();
});

const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://1090a94900de49248808693f40804d0d@o1241947.ingest.sentry.io/4505442158510081",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const pinia = createPinia();
app.component('font-awesome-icon', FontAwesomeIcon).use(router).use(pinia).mount('#app');