<template>
  <header id="header" class="mb-10 md:mb-12 pb-52">
    <div class="header-container pt-8 pb-4 relative px-4">
      <router-link class="d-inline-block" to="/">
        <h1 class="text-black font-bold">PREGIUDIZIALI E FOLLOW-UP</h1>
      </router-link>
      <NavBar />
    </div>
  </header>

  <!-- <header id="header" class="mb-10 md:mb-20">
    <div class="header-container pt-8 pb-4 relative px-4">
      <router-link class="d-inline-block" to="/">
        <h1 class="text-white">
          <div class="flex content-center items-center gap-2">
            <div><img class="max-w-[150px]" src="../../public/bg_header.png" /></div>
            <div><img class="logoTop" src="../../public/logo_bdrp.png" alt="Home" /></div>
          </div>
        </h1>
      </router-link>
      <NavBar />
    </div>
  </header> -->
</template>
<script setup>
  const appName = process.env.APP_NAME;
</script>
<script>
  import NavBar from './NavBar';
  export default {
    name: 'Header',
    components: { NavBar },
  };
</script>
<style scoped>
  header {
    width: min(100%, 1500px);
    background-image: url(../../public/bg_header_2.png);
    background-position: center 65%;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 6px 6px -6px #0005;
    background-size: cover;
  }
  .header-container {
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-items: center;
    align-items: baseline;
    width: 100%;
    z-index: 2;
    /* background-color: #00000057; */
  }
  header > .container:after {
    position: absolute;
    top: 0;
    height: 100%;
    /* content: ''; */
    box-shadow: 0 0 0 100vmax #00000057;
    clip-path: inset(0 -100vmax);
    background-color: #00000057;
    z-index: 1;
  }
  .logoTop {
    max-width: 250px;
  }
  @media screen and (max-width: 339.98px) {
    .logoTop {
      max-width: 100%;
    }
  }
</style>
