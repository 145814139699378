<template>
  <!-- <div class="navigation text-grigio flex-1 text-right" :data-route-name="this.$route.name">
    <router-link to="/" :class="`text-sm inline-block text-grigio ${this.$route.name == 'Home' || this.$route.name == 'Sentenza' ? 'font-bold' : ''}`" role="link" title="Trova sentenze" @click="searchStore.clear()"><span class="inline-block mr-3">Trova sentenze</span> |</router-link>
    <router-link to="/progetto" :class="`text-sm inline-block ml-3 text-grigio ${this.$route.name == 'Project' ? 'font-bold' : ''}`" role="link" title="Il progetto"><span class="inline-block mr-3">Il progetto</span> |</router-link>
    <router-link to="/ricerca" :class="`text-sm inline-block ml-3 text-grigio ${this.$route.name == 'Search' ? 'font-bold' : ''}`" role="link" title="La ricerca"><span class="inline-block mr-3">La ricerca</span> |</router-link>
    <router-link to="/chi-siamo" :class="`text-sm inline-block ml-3 text-grigio ${this.$route.name == 'About' ? 'font-bold' : ''}`" role="link" title="Chi siamo">Chi siamo</router-link>
  </div> -->

  <nav class="">
    <div class="container flex flex-wrap items-center justify-between mx-auto relative">
      <!-- <a href="javascript:;" @click="toggleNav()" class="btn small inverse inline-flex items-center text-sm rounded-lg md:hidden" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <span><font-awesome-icon icon="fa-solid fa-bars" /></span>
      </a> -->
      <button class="hmenu button-three md:hidden" aria-controls="primary-navigation" :aria-expanded="ariaExpanded" @click="toggleNav()" :data-state="currentState">
        <svg stroke="var(--button-color)" fill="none" class="hamburger" viewBox="-10 -10 120 120" width="50">
          <g id="scalingcontainer">
            <path class="line" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" d="m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 30 v 70"></path>
          </g>
        </svg>
      </button>

      <div class="w-full md:block md:w-auto bg-grigio rounded-full px-4 py-2" id="navbar-default" :aria-expanded="ariaExpanded" :data-route-name="this.$route.name">
        <ul class="flex flex-col p-0 m-0 md:flex-row">
          <li>
            <router-link to="/" :class="`text-xl md:text-base inline-block text-primary-color ${this.$route.name == 'Home' || this.$route.name == 'Sentenza' ? 'font-bold' : ''}`" role="link" title="Trova sentenze" @click="searchStore.clear()">
              <span class="inline-block md:mr-3">Cerca</span>
              <span class="hidden md:inline">|</span>
            </router-link>
          </li>
          <li>
            <router-link to="/progetto" :class="`text-xl md:text-base inline-block md:ml-3 text-primary-color ${this.$route.name == 'Progetto' ? 'font-bold' : ''}`" role="link" title="Il progetto">
              <span class="inline-block md:mr-3">Il progetto</span>
              <span class="hidden md:inline">|</span>
            </router-link>
          </li>
          <li>
            <router-link to="/ricerca" :class="`text-xl md:text-base inline-block md:ml-3 text-primary-color ${this.$route.name == 'Ricerca' ? 'font-bold' : ''}`" role="link" title="La ricerca">
              <span class="inline-block md:mr-3">La ricerca</span>
              <span class="hidden md:inline">|</span>
            </router-link>
          </li>
          <li>
            <router-link to="/chi-siamo" :class="`text-xl md:text-base inline-block md:ml-3 text-primary-color ${this.$route.name == 'About' ? 'font-bold' : ''}`" role="link" title="Chi siamo">Chi siamo</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'NavBar',
  };
</script>

<script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useSearchStore } from '../stores/search';

  const router = useRouter();

  const searchStore = useSearchStore();

  const navVisible = ref(false);
  const currentState = ref('closed');
  const ariaExpanded = ref(false);
  const toggleNav = () => {
    navVisible.value = !navVisible.value;
    if (currentState.value === 'closed') {
      currentState.value = 'opened';
      ariaExpanded.value = true;
    } else {
      currentState.value = 'closed';
      ariaExpanded.value = false;
    }
  };
</script>

<style>
  button.hmenu {
    background: transparent;
    border: 2px solid var(--button-color, #fff);
    border-radius: 10px;
  }

  .button-three {
    --button-color: white;
    overflow: hidden;
  }

  .button-three .hamburger {
    transition: translate 1s, rotate 1s;
  }

  .button-three[aria-expanded='true'] .hamburger {
    translate: 3.5px -3px;
    rotate: 0.125turn;
  }

  .button-three .line {
    transition: 0.5s;
    stroke-dasharray: 60 31 60 300;
  }

  .button-three[aria-expanded='true'] .line {
    stroke-dasharray: 60 105 60 300;
    stroke-dashoffset: -90;
  }

  @media screen and (max-width: 767.98px) {
    #navbar-default {
      position: absolute;
      top: 130%;
      background: var(--grigio);
      width: auto;
      right: 0;
      overflow: hidden;
      transition: 0.5s;
    }
    #navbar-default[aria-expanded='false'] {
      max-height: 0;
    }
    #navbar-default[aria-expanded='true'] {
      max-height: 300px;
    }
    #navbar-default ul {
      padding: 30px;
    }
    #navbar-default ul li a {
      white-space: nowrap;
      display: block;
      text-align: right;
      color: var(--grigio-scuro);
    }
  }
</style>
